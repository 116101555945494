import { type ChannelGroup } from 'types/channel_group';
import { getRequest } from 'utils/rest-api-util';
import { StringUtil } from 'utils/string-util';

export type ChannelGroupsResponse = {
  currentMonthChannelGroups: ChannelGroup[];
  prevMonthChannelGroups: ChannelGroup[];
};

export const getChannelGroups = async (
  analyticId: string,
  searchParams?: URLSearchParams,
): Promise<ChannelGroupsResponse> => {
  const requestPath = `/api/v1/analytics/${analyticId}/channel_groups`;

  const response = await getRequest(requestPath, searchParams);

  return StringUtil.snakeObjectToCamelObject(
    await response.json(),
  ) as ChannelGroupsResponse;
};
